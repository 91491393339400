// pages/ChatBedrock.js
import React from 'react';

const ChatBedrock = () => {
  return (
    <div>
      <h1>Chat with Bedrock -- this is it!</h1>
      <p>This is the Bedrock chatbot page.</p>
    </div>
  );
};

export default ChatBedrock;