/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://65hdbttjq8.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:f17882e2-8f22-4871-a1e1-7723336d1be5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_yV16aFxKY",
    "aws_user_pools_web_client_id": "2l2lojst116nt98ka9191fsvdm",
    "oauth": {
        "domain": "foxriverai3129-prod-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://foxriverai052fed75-052fed75-prod.auth.us-east-1.amazoncognito.com/oauth2/idpresponse/,https://app.relufox.ai/oauth2/idpresponse/,http://localhost:3000/oauth2/idpresponse/",
        "redirectSignOut": "https://relufox.ai/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "AMAZON",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
