import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import Layout from '../components/Layout';
import '../styles/main.css';
import aiImage from '../assets/ai-image.jpg';
import dataPreparationImage from '../assets/data-preparation.jpg';
import chatBotVideo from '../assets/ChatBot.mp4';
import predictiveAnalyticsVideo from '../assets/predictiveAnalytics1.mp4';
import predictiveAnalyticsImage from '../assets/predictive-analytics.jpg';
import computerVisionImage from '../assets/computer-vision.jpg';
import nlpImage from '../assets/nlp.jpg';
import recommendationSystemImage from '../assets/recommendation-system.jpg';
import secureHealthcareImage from '../assets/secureHealthcare.jpg';

const services = [
  {
    title: "Data Preparation",
    description: "Preparing your data for AI training involves more than just cleaning—it requires a deep understanding of data science techniques. We provide end-to-end data preparation services, including data cleansing, normalization, and transformation. Our team applies advanced methods such as Principal Component Analysis (PCA) and t-SNE for dimensionality reduction, ensuring your data is structured, optimized, and ready for robust model training. We also ensure that all data handling processes adhere to stringent security standards, including HIPAA for healthcare data and GDPR for data protection, so you can trust that your sensitive information is safe with us.",
    image: dataPreparationImage,
  },
  {
    title: "Conversational Agents",
    description: "At Fox River AI, we manage all aspects of conversation management, ensuring that your Conversational Agents are as effective and engaging as possible. Our solutions include advanced techniques such as Retrieval-Augmented Generation (RAG) and Chain of Thought prompting to enhance the performance and accuracy of AI-driven interactions. We offer you the opportunity to experience both OpenAI and AWS Bedrock models, allowing you to choose the solution that best fits your needs. For clients who prioritize data privacy, we also offer custom in-house models that keep your sensitive information secure within your own environment.",
    video: chatBotVideo, // Use the video instead of the image
  },
  {
    title: "Predictive Analytics",
    description: "Leverage our AI models to predict future trends, customer behavior, and operational outcomes. Our predictive analytics solutions help you make informed decisions and stay ahead of the competition. At Fox River AI, we offer more than just advanced algorithms; we provide expertise from in-house Subject Matter Experts (SMEs) across various disciplines and industries. This multidisciplinary approach allows us to tailor our predictive analytics solutions to meet the unique needs of different sectors, including retail, healthcare, finance, manufacturing, and more.",
    video: predictiveAnalyticsVideo, // Use the predictive analytics video instead of an image
  },
  {
    title: "Prescriptive Analytics",
    description: "Our prescriptive analytics solutions go a step further by not only predicting future outcomes but also providing actionable recommendations to optimize business strategies and operations. With insights from our diverse team of SMEs, we can identify the best course of action for your specific challenges, ensuring that you maximize the benefits of our AI-driven insights. Whether you need to optimize your supply chain, improve customer engagement, or enhance operational efficiency, our prescriptive analytics solutions provide the guidance you need to make data-driven decisions with confidence.",
    image: predictiveAnalyticsImage, // Use the predictive analytics image for Prescriptive Analytics
  },
  {
    title: "Recommendation Systems",
    description: "Elevate your business by delivering hyper-personalized experiences with our state-of-the-art recommendation systems. Our AI models go beyond basic algorithms, delving deep into user behavior, preferences, and even real-time interactions to craft recommendations that resonate with each customer. Whether you're aiming to boost sales, increase customer loyalty, or enhance user engagement, our recommendation systems provide the intelligence to deliver precisely what your audience wants—when they want it. From product suggestions to personalized content, our solutions ensure that every interaction feels tailored, driving both satisfaction and conversion rates to new heights.",
    image: recommendationSystemImage,
  },
  {
    title: "Computer Vision",
    description: "Unlock the full potential of visual data with our cutting-edge computer vision solutions. At Fox River AI, we leverage AI-powered image and video analysis to automate and optimize a wide array of tasks, from quality control in manufacturing to inventory tracking and object detection in complex environments. Imagine streamlining your operations with real-time defect detection that ensures your products meet the highest standards, or deploying smart cameras that monitor and manage inventory with unparalleled accuracy. Whether it's enhancing security through facial recognition or driving innovation in industries like retail, logistics, and healthcare, our computer vision solutions deliver the precision, speed, and intelligence needed to transform your visual data into actionable insights.",
    image: computerVisionImage,
  },
  {
    title: "Natural Language Processing (NLP)",
    description: "Empower your applications to truly understand and engage with human language through our advanced NLP solutions. At Fox River AI, we take NLP to the next level, enabling your systems to not only interpret and generate language but also to capture the nuances, emotions, and intentions behind the words. Whether it's extracting deep insights through sentiment analysis, delivering seamless language translation across global markets, or creating conversational agents that feel almost human, our NLP models are designed to elevate communication and drive meaningful interactions. From chatbots that can hold context-rich conversations to AI systems that can read and comprehend large volumes of text, our NLP solutions unlock the full spectrum of language's power in your business.",
    image: nlpImage,
  },
  {
    title: "Custom AI Solutions",
    description: "At Fox River AI, we don't believe in one-size-fits-all. Our team of seasoned data scientists and engineers work closely with you to craft bespoke AI models that align perfectly with your business goals and challenges. Whether you’re aiming to revolutionize customer service, streamline complex operations, or unlock hidden insights from your data, our custom AI solutions are designed to deliver transformative results. We specialize in building AI models that are not only powerful but also highly adaptable, ensuring they evolve with your business and continue to drive success. From initial consultation to deployment and beyond, we provide end-to-end support, turning your unique vision into reality with precision and innovation.",
    image: aiImage,
  },
];

const HomePage = ({ user }) => {
  const isAuthenticated = user !== null; // Check if user is authenticated

  if (!isAuthenticated) {
    return <div>Please sign in to access this page.</div>;
  }
  return (
    <Layout>
      <div className="container homepage">
        <div className="page-heading">
          <h1>Welcome to Fox River AI</h1>
          <div className="intro">
            <div className="intro-column">
              <p>Welcome to the inner layer of Fox River AI, where powerful AI solutions come to life at affordable prices. Let our dedicated team of experts handle the complexities of AI while you focus on running your business. We build AI solutions customized for your specific use cases, allowing you to leverage cutting-edge technology without diverting attention from your core activities.</p>
              <p>At Fox River AI, we are focused on democratizing and commoditizing AI so that small, medium, and large businesses can all leverage the benefits of AI. We understand that AI is a vast field, and while we can't be everything to all companies, we can offer commonly used AI algorithms across various industries.</p>
            </div>
            <div className="intro-column">
              <p>For example, Conversational Agents can be used to enhance customer service, streamline support, and improve user engagement across sectors such as retail, healthcare, and finance. Data processing is not the same for every industry, but there are enough similarities that our team of seasoned data engineers can transform your data to meet the stringent specifications for AI training. Our goal is to make these powerful tools accessible and useful for businesses of all sizes, enabling them to harness the power of AI without the need for extensive in-house expertise.</p>
            </div>
          </div>
        </div>

        <section className="services">
          <h2 className="section-heading">Our Services</h2>
          {services.map((service, index) => (
            <div className={`service ${index % 2 === 0 ? 'left' : 'right'}`} key={index}>
              <div className="service-media">
                {service.video ? (
                  <video className="service-video" src={service.video} autoPlay loop muted playsInline />
                ) : (
                  <img src={service.image} alt={service.title} />
                )}
              </div>
              <div className="service-content">
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                  {service.title === "Data Preparation" && isAuthenticated && (
                    <Link
                      to="/data-preparation"
                      className="cta-button"
                    >
                      Explore Data Preparation
                    </Link>
                  )}
                </div>
                </div>
          ))}
        </section>

        <section className="security">
          <h2 className="section-heading">Security and Privacy</h2>
          <div className="security-content-wrapper">
            <div className="security-content">
              <p>At Fox River AI, safeguarding your data is our highest priority. We employ rigorous data protection protocols to ensure your information remains confidential, secure, and compliant with industry standards at every stage of the AI development process. From encryption and access controls to regular audits and compliance with regulations like GDPR and HIPAA, we are committed to maintaining the highest levels of security so you can innovate with confidence.</p>
            </div>
            <div className="security-media">
              <img src={secureHealthcareImage} alt="Security and Privacy" />
            </div>
          </div>
        </section>      

        <section className="section why-choose-us">
          <h2 className="section-heading">Why Choose Us?</h2>
          <ul>
            <li><strong>Affordability:</strong> High-quality AI solutions without the high cost.</li>
            <li><strong>Expertise:</strong> A team of seasoned data scientists and engineers at your service.</li>
            <li><strong>Customization:</strong> AI models tailored to your unique business needs.</li>
            <li><strong>Support:</strong> Comprehensive support and guidance throughout your AI journey.</li>
          </ul>
          <p>Join us at Fox River AI and unlock the full potential of artificial intelligence for your business. Let us handle the complexities of AI development while you focus on leveraging its benefits.</p>
        </section>
      </div>
    </Layout>
  );
};

export default HomePage;