import React, { useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import './Header.css';
import logo from '../assets/Fox-logo.svg';
import Navigation from './Navigation';

const Header = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const namesToSearch = ["Randall", "Randy", "Shane"];

  // Function to search through the user object
  const searchNamesInObject = (obj, names) => {
    for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        // Recursively search in nested objects
        const result = searchNamesInObject(obj[key], names);
        if (result) return result;
      } else if (typeof obj[key] === 'string') {
        // Check if any of the names are found in the string
        for (let name of names) {
          if (obj[key].includes(name)) {
            return obj[key]; // Return the first matching value
          }
        }
      }
    }
    return null;
  };

  const displayName = searchNamesInObject(user, namesToSearch) || user?.attributes?.email || 'User';

  const handleSignOut = async () => {
    try {
      await signOut();
      window.location.href = 'https://relufox.ai';
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  useEffect(() => {
    console.log('User object:', user);
  }, [user]);

  return (
    <header className="header">
      <div className="header-top">
        <div className="header-left">
          <img src={logo} alt="Company Logo" className="logo" />
          <div className="company-info">
            <h1 className="company-name">Fox River AI</h1>
            <p className="slogan">Affordable AI, Powerful Solutions</p>
          </div>
        </div>
        <div className="header-right">
          <p className="contact-info">Call today: (630) 940-8837</p>
          {user && (
            <button onClick={handleSignOut} className="logout-link">
              {displayName} (Log out)
            </button>
          )}
        </div>
      </div>
      <Navigation />
    </header>
  );
};

export default Header;