import React from 'react';
import CancelButton from './CancelButton';

const Footer = () => {
  return (
    <footer style={{ textAlign: 'center', padding: '10px', backgroundColor: '#1E1E1E' }}>
      <CancelButton />
    </footer>
  );
};

export default Footer;