import React from 'react';
import { Link } from 'react-router-dom';

const Navigation = () => {
  return (
    <nav style={{ backgroundColor: '#1E1E1E', padding: '10px', textAlign: 'center' }}>
      <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
        <li style={{ display: 'inline', margin: '0 10px' }}>
          <Link to="/" style={{ color: '#FFFFFF', textDecoration: 'none' }}>Home</Link>
        </li>
        <li style={{ display: 'inline', margin: '0 10px' }}>
          <Link to="/chatbot" style={{ color: '#FFFFFF', textDecoration: 'none' }}>Chatbot</Link>
        </li>
        <li style={{ display: 'inline', margin: '0 10px' }}>
          <Link to="/data-preparation" style={{ color: '#FFFFFF', textDecoration: 'none' }}>Data Preparation</Link>
        </li>
        {/* Add more navigation links as needed */}
      </ul>
    </nav>
  );
};

export default Navigation;
