// src/components/CancelButton.js
const CancelButton = () => {
  const handleCancel = () => {
    window.location.href = 'https://relufox.ai';
    window.close();
  };

  return (
    <button onClick={handleCancel} style={{ color: '#FF9900', textDecoration: 'none', background: 'none', border: 'none', cursor: 'pointer', padding: '0' }}>
      Cancel
    </button>
  );
};

export default CancelButton;
