// App.js
import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react';
import { createTheme, defaultTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';
import CancelButton from './components/CancelButton';
import './custom-styles.css';
import foxLogo from './assets/Fox-logo.svg';
import HomePage from './pages/HomePage';
import ChatBedrock from './pages/ChatBedrock';

Amplify.configure(awsconfig);

const myDarkTheme = createTheme({
  ...defaultTheme,
  name: 'relu-dark-theme',
  tokens: {
    colors: {
      brand: {
        primary: {
          80: '#FF9900',
        },
      },
      background: {
        primary: '#121212',
      },
      font: {
        primary: {
          value: 'Arial',
        },
      },
    },
  },
});

const AppContent = ({ signOut, user }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the home page after authentication
    navigate('/');
  }, [navigate]);

  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage user={user} />} />
        <Route path="/chat-bedrock" element={<ChatBedrock user={user} />} />
      </Routes>
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <button onClick={signOut}>Sign out</button>
      </div>
    </>
  );
};

function App() {
  const logoRef = useRef(null);

  return (
    <ThemeProvider theme={myDarkTheme}>
      <Authenticator
        components={{
          Header() {
            return (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#1E1E1E', padding: '10px' }}>
                <img
                  src={foxLogo}
                  alt="Fox Logo"
                  id="foxLogo"
                  style={{ width: '50px', marginRight: '10px' }}
                  ref={logoRef}
                />
                <div>
                  <h1 style={{ color: '#FF9900', margin: 0, textAlign: 'center' }}>Fox River AI</h1>
                  <p style={{ color: '#FFFFFF', margin: 0, textAlign: 'center' }}>Affordable AI, Powerful Solutions</p>
                </div>
              </div>
            );
          },
          Footer() {
            return (
              <div style={{ textAlign: 'center', padding: '10px', backgroundColor: '#1E1E1E' }}>
                <CancelButton />
              </div>
            );
          },
        }}
      >
        {({ signOut, user }) => (
          <Router>
            <AppContent signOut={signOut} user={user} />
          </Router>
        )}
      </Authenticator>
    </ThemeProvider>
  );
}

export default App;